<template>
	<ValidationObserver v-slot="{ valid, invalid, validated, passes, validate, handleSubmit, errors }" ref="observer">
		<div class="fill-height  pa-5 pa-sm-10" v-if="AppStore.started_app" id="appreciation-page">
			<v-container class="fill-height d-flex align-center flex-row">
				<v-row class="">
					<v-card elevation="0" class="mx-auto pa-0 pa-sm-5 mb-3 mb-sm-5" v-show="!AppStore.toggleCGU"
					        rounded="xl">
						<stepper :step="3" :steps="3"
						         v-if="$vuetify.breakpoint.smAndUp && AppStore.data.configuration.display_stepper"></stepper>

						<v-col cols="12" class="text-h2 pt-5 pb-7 text-center my-auto" id="appreciation-title"
						       v-if="AppStore.data.configuration.display_appreciation_title">
        						<span v-if="Helpers.isNegativeWithComment()"
						              v-html="AppStore.data.configuration[AppStore.locale].title_3_negative_with_comment"></span>
							<span v-if="Helpers.isNegativeWithoutComment()"
							      v-html="AppStore.data.configuration[AppStore.locale].title_3_negative_without_comment"></span>
							<span v-if="Helpers.isPositiveWithComment()"
							      v-html="AppStore.data.configuration[AppStore.locale].title_3_positive_with_comment"></span>
							<span v-if="Helpers.isPositiveWithoutComment()"
							      v-html="AppStore.data.configuration[AppStore.locale].title_3_positive_without_comment"></span>
						</v-col>
						<v-col cols="12" class="">
							<div v-for="(question, questionIndex) in appreciation_questions_to_display"
							     :key="questionIndex" class="appreciation-questions">
								<divider-view v-if="question.format === 'divider'"></divider-view>
								<slider-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								             v-if="question.format === 'slider'"
								             :rules="question.is_required ? { regex: /^[1-5]+$/ } : ''"
								             v-model="appreciation_questions_to_display[questionIndex]"
								             :questionIndex="questionIndex" :lang="AppStore.locale"></slider-view>
								<text-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								           v-if="question.format === 'text'"
								           :rules="question.is_required ? 'required' : ''"
								           v-model="appreciation_questions_to_display[questionIndex]"
								           :questionIndex="questionIndex" :lang="AppStore.locale"></text-view>
								<textarea-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								               v-if="question.format === 'textarea'"
								               :rules="question.is_required ? 'required' : ''"
								               v-model="appreciation_questions_to_display[questionIndex]"
								               :questionIndex="questionIndex" :lang="AppStore.locale"></textarea-view>
								<select-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								             v-if="question.format === 'select'"
								             :rules="question.is_required ? 'required' : ''"
								             v-model="appreciation_questions_to_display[questionIndex]"
								             :questionIndex="questionIndex" :lang="AppStore.locale"></select-view>
								<checkbox-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								               v-if="question.format === 'checkbox'"
								               :color="AppStore.data.general.primary_color"
								               :rules="question.is_required ? 'required' : ''"
								               v-model="appreciation_questions_to_display[questionIndex]"
								               :questionIndex="questionIndex" :lang="AppStore.locale"></checkbox-view>
								<radio-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								            v-if="question.format === 'radio'"
								            :color="AppStore.data.general.primary_color"
								            :rules="question.is_required ? 'required' : ''"
								            v-model="appreciation_questions_to_display[questionIndex]"
								            :questionIndex="questionIndex" :lang="AppStore.locale"></radio-view>
								<switch-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"
								             v-if="question.format === 'switch'"
								             :color="AppStore.data.general.primary_color"
								             :rules="question.is_required ? 'required' : ''"
								             v-model="appreciation_questions_to_display[questionIndex]"
								             :questionIndex="questionIndex" :lang="AppStore.locale"></switch-view>
							</div>
						</v-col>
						<v-col cols="12" class="text-center" v-if="!AppStore.toggleCGU">
							<v-btn v-if="questionAnswerLength === Object.keys(AppStore.rating.questions_answers).length"
							       @click="passes(submit); AppStore.rating.questions_answers = {}"
							       @keypress.enter="navigate" role="link" rounded
							       outlined elevation="0"
							       :large="AppStore.data.general.button_size === 'large'"
							       :x-large="AppStore.data.general.button_size === 'x-large'"
							       :x-small="AppStore.data.general.button_size === 'x-small'"
							       :small="AppStore.data.general.button_size === 'small'"
							       :color="AppStore.data.general.primary_color.hexa" class="revert-btn text-button"
							       :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
							       v-html="AppStore.data.configuration[AppStore.locale].appreciation_retry_btn_text">
							</v-btn>
							<v-btn v-if="Object.keys(AppStore.rating.questions_answers).length > questionAnswerLength"
							       @click="passes(submit)" role="link" rounded
							       elevation="0" dark
							       :large="AppStore.data.general.button_size === 'large'"
							       :x-large="AppStore.data.general.button_size === 'x-large'"
							       :x-small="AppStore.data.general.button_size === 'x-small'"
							       :small="AppStore.data.general.button_size === 'small'"
							       @keypress.enter="navigate"
							       :color="AppStore.data.general.primary_color.hexa"
							       class="text-button"
							       :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
							       v-html="AppStore.data.configuration[AppStore.locale].appreciation_validate_btn_text">
							</v-btn>
						</v-col>
					</v-card>

					<v-card v-show="AppStore.toggleCGU" elevation="0" class="pa-5 fill-height" rounded="xl"
					        id="cgu-card" style="overflow-y: auto;">
						<v-icon @click="AppStore.toggleCGU = false" class="float-right" size="30" id="close-cgu">mdi
							mdi-close
						</v-icon>
						<div v-html="AppStore.data.configuration[AppStore.locale].cgu_content"></div>
					</v-card>

					<v-col cols="12 text-center" v-if="AppStore.toggleCGU">
						<v-btn @click="AppStore.toggleCGU = false" role="link" rounded
						       elevation="0" dark
						       :large="AppStore.data.general.button_size === 'large'"
						       :x-large="AppStore.data.general.button_size === 'x-large'"
						       :x-small="AppStore.data.general.button_size === 'x-small'"
						       :small="AppStore.data.general.button_size === 'small'"
						       @keypress.enter="navigate"
						       class="text-button mt-3"
						       :color="AppStore.data.general.primary_color.hexa"
						       :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
						       v-html="AppStore.data.configuration[AppStore.locale].cgu_close_btn">
						</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</ValidationObserver>

</template>

<script>
import VTextAreaWithValidation from "../../../components/communs/inputs/VTextAreaWithValidation.vue";
import AppStore from "../../../stores/AppStore";
import _ from 'lodash';
import SliderView from '../../../components/communs/inputViewer/SliderView.vue'
import TextView from '../../../components/communs/inputViewer/TextView.vue'
import TextareaView from '../../../components/communs/inputViewer/TextareaView.vue'
import SelectView from '../../../components/communs/inputViewer/SelectView.vue'
import CheckboxView from '../../../components/communs/inputViewer/CheckboxView.vue'
import RadioView from '../../../components/communs/inputViewer/RadioView.vue'
import SwitchView from '../../../components/communs/inputViewer/SwitchView.vue'
import {EventBus} from "../../../services/Request";
import Helpers from "../../../services/Helpers";
import DividerView from "../../../components/communs/inputViewer/DividerView.vue";
import Stepper from "../../../components/communs/Stepper.vue";
import {ValidationObserver} from 'vee-validate';

export default {
	name: 'appreciation-view',

	components: {
		Stepper,
		ValidationObserver,
		DividerView,
		VTextAreaWithValidation,
		SliderView,
		TextView,
		TextareaView,
		SelectView,
		CheckboxView,
		RadioView,
		SwitchView,
	},

	props: {},

	data: () => ({
		AppStore,
		outlinedTextArea: false,
		questions: [],
		appreciation_questions_to_display: [],
		questionAnswerLength: 0,
	}),

	created() {
		EventBus.$on('update-question', (data) => {
			if (data.show) {
				// console.log(this.$refs['qi' + data.id])
				this.$refs['qi' + data.id][0].$el.classList.remove('d-none')
			} else {
				// console.log(this.$refs['qi' + data.id])
				this.$refs['qi' + data.id][0].$el.classList.add('d-none')
				delete AppStore.rating.questions_answers[data.id]
				this.$refs['qi' + data.id][0].sliderValue = 0
			}
		});

		setTimeout(() => {
			this.calculCguHeight()
		}, 300);

		window.addEventListener("orientationchange", () => {
			this.calculCguHeight()
		}, false);

		window.addEventListener("resize", () => {
			this.calculCguHeight()
		}, false);
	},

	async mounted() {
		this.questionAnswerLength = Object.keys(AppStore.rating.questions_answers).length;
		this.questions = []
		this.appreciation_questions_to_display = []
		await this.getBookmarkedQuestions()
		if (this.appreciation_questions_to_display.length < AppStore.data.configuration.appreciation_questions_to_display) {
			await this.getRandomQuestions();
		}
	},

	computed: {
		Helpers() {
			return Helpers
		},
		_() {
			return _;
		}
	},

	watch: {},

	methods: {
		getBookmarkedQuestions() {
			this.appreciation_questions_to_display = Object.assign([], AppStore.bookmarkedQuestions)
		},

		getRandomQuestions() {
			const max = AppStore.data.configuration.appreciation_questions_to_display - this.appreciation_questions_to_display.length
			this.questions = Object.assign([], AppStore.appreciation_questions)

			for (let i = 0; i < max; i++) {
				const randomQuestionIndex = Math.floor(Math.random() * (this.questions.length));
				this.appreciation_questions_to_display.push(this.questions[randomQuestionIndex]);
				this.questions.splice(randomQuestionIndex, 1)
			}
		},
		submit() {
			if (AppStore.data.configuration.skip_timeline_page) {
				this.$router.push({name: 'tb-2-home'})
			} else {
				this.$router.push({name: 'tb-2-timeline'})
			}
			EventBus.$emit('storeRating', 'noReset')
		},

		calculCguHeight() {
			const height = window.innerHeight - document.querySelector('#header').offsetHeight - document.querySelector('#footer').offsetHeight - 170 + 'px'
			document.getElementById("cgu-card").style.height = height;
		},

	}
};
</script>
<style>

</style>
