<template>
    <div class="md-stepper-horizontal" style="position:relative!important;top:3px;">
        <div v-for="(s, si) in steps" :key="si" :class="step === s ? 'md-step active' : 'md-step'">
            <div class="md-step-circle" :class="step >= s ? 'active': ''" :style="step >= s ? 'color:white!important; background-color:'+AppStore.data.general.primary_color.hexa : 'background-color:white;color:'+AppStore.data.general.primary_color.hexa+';border: 1px solid'+AppStore.data.general.primary_color.hexa+'!important;!important'"><span>{{ s }}</span></div>
            <div class="md-step-bar-left"></div>
            <div class="md-step-bar-right"></div>
        </div>
    </div>
</template>

<script>

import AppStore from "../../stores/AppStore";

export default {
	name: 'stepper',

	components: {},

	props : {
		steps: {
			type: [Number],
			default: {}
		},
        step: {
            type: [Number],
            default: {}
        },
	},

	data: () => ({
		AppStore,
	}),

	created() {

	},

	mounted() {

	},

	computed: {

	},

	watch: {

    },

	methods: {

    }
};
</script>
<style>
.md-stepper-horizontal {
    display:table;
    width:100%;
    margin:0 auto;
    position: absolute;
    top: 25px;
    left: 0;
}
.md-stepper-horizontal .md-step {
    display:table-cell;
    position:relative;
    padding:24px;
}
.md-stepper-horizontal .md-step:active {
    border-radius: 15% / 75%;
}
.md-stepper-horizontal .md-step:first-child:active {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.md-stepper-horizontal .md-step:last-child:active {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.md-stepper-horizontal .md-step:hover .md-step-circle {
    background-color:#757575;
}
.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
    display:none;
}
.md-stepper-horizontal .md-step .md-step-circle {
    width:30px;
    height:30px;
    margin:0 auto;
    border-radius: 50%;
    text-align: center;
    line-height:30px;
    font-size: 16px;
    font-weight: 600;
    color:#FFFFFF;
}
.md-stepper-horizontal.green .md-step.active .md-step-circle {
    background-color:#00AE4D;
}
.md-stepper-horizontal .md-step.active .md-step-circle {
    background-color: rgb(33,150,243);
}
.md-stepper-horizontal .md-step.done .md-step-circle:before {
    font-weight:100;
    content: "\f00c";
}
.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
    position:absolute;
    top:36px;
    height:1px;
    border-top:1px solid #DDDDDD;
}
.md-stepper-horizontal .md-step .md-step-bar-right {
    right:0;
    left:50%;
    margin-left:20px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
    left:0;
    right:50%;
    margin-right:20px;
}
.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
    background: rgba(0, 0, 0, 0.38);
    background: white;
    color: #084685E3;
}
.v-stepper__header{
    box-shadow:none;
}
</style>